import { SVGProps } from 'react';

interface ISvgProps extends SVGProps<SVGSVGElement> {
  secondaryFill?: string;
}
export const On3Header = ({ fill, secondaryFill, ...rest }: ISvgProps) => {
  return (
    <svg
      fill={fill ?? 'white'}
      height="24"
      viewBox="0 0 55 24"
      width="55"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M16.4198 15.9681C16.1367 16.8765 15.7121 17.6414 15.0987 18.263C14.4853 18.8845 13.7304 19.4104 12.8339 19.745C11.8902 20.0797 10.805 20.2709 9.53104 20.2709H7.31342C6.03947 20.2709 4.95425 20.0797 4.01059 19.745C3.06692 19.4104 2.31198 18.8845 1.74578 18.263C1.1324 17.6414 0.70775 16.8765 0.42465 15.9681C0.14155 15.0598 0 14.0558 0 12.9562V10.2789C0 9.13148 0.14155 8.12749 0.42465 7.21913C0.70775 6.31076 1.1324 5.54582 1.74578 4.8765C2.35917 4.25498 3.1141 3.72909 4.01059 3.39443C4.95425 3.05977 6.03947 2.86853 7.31342 2.86853H9.53104C10.805 2.86853 11.8902 3.05977 12.8339 3.39443C13.7775 3.72909 14.5325 4.25498 15.0987 4.8765C15.7121 5.49801 16.1367 6.31076 16.4198 7.21913C16.7029 8.12749 16.8445 9.13148 16.8445 10.2789V12.9562C16.8916 14.0558 16.7501 15.0598 16.4198 15.9681ZM3.77467 16.9243C4.43524 17.6892 5.52045 18.0717 7.03032 18.0717H9.90851C11.4184 18.0717 12.5036 17.6892 13.1642 16.9243C13.8247 16.1594 14.1078 15.0598 14.1078 13.5299V9.60957C14.1078 8.07969 13.7775 6.98008 13.1642 6.21514C12.5036 5.4502 11.4184 5.11554 9.90851 5.11554H7.03032C5.52045 5.11554 4.43524 5.49801 3.77467 6.21514C3.1141 6.98008 2.78382 8.07969 2.78382 9.60957V13.5299C2.78382 15.0598 3.1141 16.2072 3.77467 16.9243Z"
          fill={fill}
        />
        <path
          d="M31.6128 20.6056C30.9051 20.6056 30.3389 20.4143 29.8671 20.0319C29.4424 19.6494 29.0649 19.1713 28.6875 18.5976L22.9783 9.27489C22.8839 9.08366 22.7896 8.94023 22.6952 8.84461C22.648 8.7968 22.648 8.7968 22.6008 8.7968C22.5065 8.7968 22.4593 8.84461 22.4593 8.84461C22.4121 8.89242 22.4121 9.03585 22.4121 9.17927V20.3665H19.817V9.27489C19.817 8.46214 20.0057 7.74501 20.4304 7.17131C20.855 6.54979 21.5628 6.26294 22.5065 6.26294C23.167 6.26294 23.7332 6.45417 24.2051 6.88445C24.6297 7.26692 25.0544 7.79282 25.479 8.50995L31.0466 17.6892C31.141 17.8327 31.2354 17.9761 31.3297 18.0239C31.3769 18.0717 31.4241 18.1195 31.4713 18.1195C31.5657 18.1195 31.5657 18.0717 31.6128 18.0717C31.66 18.0239 31.66 17.9283 31.66 17.8327V6.50198H34.2079V17.737C34.2079 18.5976 33.972 19.2669 33.5474 19.7928C33.1227 20.3187 32.4621 20.6056 31.6128 20.6056Z"
          fill={fill}
        />
        <path
          d="M49.6841 24H42.0404C39.4925 24 37.4636 21.8964 37.4636 19.3625V4.63745C37.4636 2.05578 39.5397 0 42.0404 0H49.6369C52.1848 0 54.2137 2.10359 54.2137 4.63745V19.3147C54.2609 21.8964 52.1848 24 49.6841 24Z"
          fill={fill}
        />
        <path
          d="M46.3341 20.6056C46.1926 20.6056 46.0982 20.6056 45.9566 20.6056H40.7665V17.1634H46.4757C47.0419 17.0678 47.4665 16.6853 47.5609 16.2072C47.5609 16.1116 47.6081 16.016 47.6081 15.9682C47.6081 14.6295 45.2017 13.7211 44.2109 12.8128V11.2829C45.2017 10.3745 47.6081 9.37055 47.6081 8.12752C47.6081 8.0319 47.6081 7.93629 47.5609 7.88848C47.4665 7.41039 47.0419 6.98011 46.5228 6.9323H40.7665V3.44226H45.9095C46.051 3.44226 46.1454 3.44226 46.2869 3.44226C46.4285 3.44226 46.5228 3.44226 46.6172 3.44226C48.6461 3.58569 50.3919 5.06776 50.7693 7.02792C50.8165 7.31477 50.8637 7.60162 50.8637 7.93629C50.8637 9.46617 50.1088 10.8526 48.8348 11.6654L48.4102 12L48.8348 12.3347C50.1088 13.1952 50.8637 14.5817 50.8637 16.0638C50.8637 16.3506 50.8165 16.6853 50.7693 16.9721C50.3447 18.9323 48.6461 20.4144 46.6172 20.5578C46.57 20.6056 46.4285 20.6056 46.3341 20.6056Z"
          fill={secondaryFill || '#1D252C'}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect fill="white" height="24" width="54.2609" />
        </clipPath>
      </defs>
    </svg>
  );
};
