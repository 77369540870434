import { On3Header } from '@on3/ui-lib/components/Svg/On3Header';
import { NetworkFooter } from 'components/Footer/NetworkFooter';
import Head from 'next/head';
import Link from 'next/link';

import styles from './ErrorPage.module.scss';

interface IErrorPageProps {
  errorCode: string;
}

const NotFoundPage = ({ errorCode }: IErrorPageProps) => {
  return (
    <>
      <Head>
        <meta content="follow, noarchive, noindex" name="robots" />
      </Head>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <Link href="/">
            <On3Header />
          </Link>
        </header>
        <section className={styles.content}>
          <div className={styles.container}>
            <h1>{`Well that's deflating...`}</h1>
            <div>
              <p>{`It's just a ${errorCode} error.`}</p>
              <p>
                {`You didn't fumble the ball, but we can't find what you're
                looking for.`}
              </p>
            </div>
            <div>
              <a className={styles.button} href="/" title="go home">
                Okay
              </a>
            </div>
          </div>
        </section>
        <NetworkFooter className={styles.footer} />
      </div>
    </>
  );
};

export default NotFoundPage;
